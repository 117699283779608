<template>
  <div class="web_box">
    <van-nav-bar
      title="查看物流"
      left-arrow
      @click-left="toBack()"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>
    <div class="box">
      <div class="receiving_people">
        <div class="icom_box">
          <img src="../../assets/logistics/icon.png" alt="" />
        </div>
        <div class="peoprl_details">
          <p class="receiving_people_name">
            收货人：{{ logisticsData.contactsName }}
            <span>{{ logisticsData.contactsPhone }}</span>
          </p>
          <p class="receiving_people_address">
            收货地址：{{ logisticsData.address }}
          </p>
        </div>
      </div>
      <div class="line_l"></div>
    </div>
    <!-- <receive-people></receive-people> -->
    <div class="logistics_no">
      <p>
        订单编号：<span>{{ orderNo }}</span>
      </p>
      <p>
        快递单号：<span>{{ logisticsNo }}</span>
      </p>
    </div>

    <!-- step -->
    <!-- <div class="step_box">
      <div class="step_item" v-for="i in 6" :key="i">
        <div class="date_time">
          <p>11月02日</p>
          <span>12：22</span>
        </div>
        <div class="point"></div>
        <div class="status">
          <p>派送中</p>
          <span>[广州荔湾区分发中心]</span>
        </div>
        <div class="line"></div>
      </div>
    </div> -->
  </div>
</template>
<script>
import {getLogistics} from "@/utils/api";
export default {
  components: {},
  data() {
    return {
      logisticsData: "",
      logisticsNo: "",
      orderNo: "",
    };
  },
  mounted() {
    getLogistics({
      orderNo: this.$route.query.orderNo,
    })
      .then((res) => {
        this.orderNo = res.orderInfo.orderNo;
        this.logisticsNo = res.orderInfo.expressNumber;
        this.logisticsData = res.orderInfo;
      })
      .catch(() => {});
  },
};
</script>
<style lang="scss" scoped>
.step_box {
  width: 100%;
  margin-top: 30px;
  padding: 30px 20px 30px;
  background-color: #12172c;

  .step_item {
    display: flex;
    justify-items: left;
    align-items: center;
    padding: 30px 0;
    position: relative;
    .date_time {
      width: 150px;
      text-align: center;
      p {
        font-size: 26px;
        color: #fff;
        margin-bottom: 10px;
      }
      span {
        font-size: 22px;
        color: #8cbcd6;
      }
    }
    .point {
      width: 20px;
      height: 20px;
      background-image: url("../../assets/logistics/point_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin: 0 40px;
      position: relative;
    }
    .status {
      p {
        font-size: 30px;
        color: #00ccff;
        margin-bottom: 10px;
      }
      span {
        font-size: 22px;
        color: #8cbcd6;
      }
    }
  }
}
.line {
  // width: 1px;
  height: 130px;
  border: 0.5px dashed #7f89b3;
  position: absolute;
  left: 197px;
  top: 70px;
}
.step_item:last-of-type .line {
  border: none;
}
.logistics_no {
  background-color: #12172c;
  color: #00ccff;
  margin-top: 30px;
  p {
    padding: 0 30px;
    font-size: 30px;
    color: #00ccff;
    line-height: 80px;
    span {
      font-size: 22px;
      color: #8cbcd6;
    }
  }
}
.box {
  width: 100%;
  background-color: #12172c;
  margin-top: 108px;
}
.receiving_people {
  display: flex;
  align-items: center;
  padding: 51px 30px;
  position: relative;
  .icom_box {
    width: 50px;
    height: 50px;
  }
  .peoprl_details {
    width: 620px;
    margin-left: 23px;
    .receiving_people_name {
      color: #00ccff;
      font-size: 28px;
      span {
        margin-left: 30px;
      }
    }
    .receiving_people_address {
      font-size: 26px;
      color: #7f89b3;
      margin-top: 20px;
    }
  }
}
.line_l {
  width: 100%;
  height: 6px;
  background-image: url("../../assets/logistics/bg_line.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
